@import url('https://unpkg.com/@tailwindcss/typography@0.2.x/dist/typography.min.css');
@import url('https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css');
@import url('https://fonts.googleapis.com/css2?family=Saira+Condensed:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

body {
  background-color: #000000;
  color: #f5f5f5;
}

p {
  text-shadow: -.375px .375px 0 #06f5d8, -.75px .75px 0 #7a39b2;
}

li  {
  text-shadow: -.375px .375px 0 #06f5d8, -.75px .75px 0 #7a39b2;
}

.cursive {
  font-family: "Saira Condensed", cursive;
  font-weight: 700;
}

.prose img {
  margin-left: auto;
  margin-right: auto;
  border-radius: 0.313rem;
}

.home-name  {
  font-size: 9.375rem;
}

.about {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.about-image {
  display: flex;
  justify-content: center;
  height: 100vh;
  padding: 0;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}

.about-image:active {
  opacity: 0;
  transform: scale(-.5);
}

.image {
  position: absolute;
  z-index: -1;
}

.background {
  position: absolute;
  z-index: -2;
}

.speech-bubble {
  position: absolute;
  z-index: -1;
}

.typing {
  display: flex;
  justify-content: center;
  font-weight: bolder;
  font-family: 'Press Start 2P', cursive;
  text-align: center;
  text-shadow: -.15px .15px 0 #06f5d8, -.3px .3px 0 #7a39b2;
}

.projects-typing {
  display: flex;
  justify-content: center;
  font-size: xx-large;
  font-weight: bolder;
  font-family: 'Press Start 2P', cursive;
  text-align: center;
  text-shadow: -.15px .15px 0 #06f5d8, -.3px .3px 0 #7a39b2;
}

.swipe-right-typing {
  display: flex;
  justify-content: center;
  font-size: x-large;
  font-weight: bolder;
  font-family: 'Press Start 2P', cursive;
  text-align: center;
  text-shadow: -.15px .15px 0 #06f5d8, -.3px .3px 0 #7a39b2;
}

.resume-typing {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: xx-large;
  font-weight: bolder;
  font-family: 'Press Start 2P', cursive;
  text-align: center;
  text-shadow: -.15px .15px 0 #06f5d8, -.3px .3px 0 #7a39b2;
}

.take-one-typing {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: x-large;
  font-weight: bolder;
  font-family: 'Press Start 2P', cursive;
  text-align: center;
  text-shadow: -.15px .15px 0 #06f5d8, -.3px .3px 0 #7a39b2;
}

.about-text-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 3rem;
}

.about-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  text-align: center;
  text-shadow: -.375px .375px 0 #06f5d8, -.75px .75px 0 #7a39b2;
}

.about-text h2 {
  font-size: 2.5rem;
}

.scroll-div {
  width: 100%;
  padding-bottom: 7rem;
}

.scroll-container {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.scrolling-text {
  position: absolute;
  font-size: 5rem;
  white-space: nowrap;
  overflow: hidden;
  font-family: "Saira Condensed", cursive;
  min-width: 100%;
  text-shadow: -1px 1px 0 #06f5d8, -2px 2px 0 #7a39b2;
  /* font-family: 'Press Start 2P', cursive; */
}

.skills-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.skill {
  height: 100%;
  width: 100%;
  text-align: center;
}

.front-end-skills {
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 50%;
  width: 50%;
  right: 0;
  text-align: center;
  background-image: url('https://i.imgur.com/54VlJo8.png');
  background-size: 25%;
  transition: all 0.3s ease-in-out;
  background-repeat: no-repeat;
  border-style: solid;
  border-width: .25rem;
  border-image: 
    linear-gradient(
      to bottom, 
      #7a39b2, 
      #000000
    ) 1 100%;
  padding: 1rem;
  list-style: none;
}

.front-end-skills:hover {
  background-size: 30%;
}

.front-end-skills li::before {
  content: "\2022";
  color: #06f5d8; /* Change the color */
  font-size: 2rem;
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 2rem; /* Also needed for space (tweak if needed) */
}

.front-end-skills h2 {
  padding-bottom: 2rem;
  font-family: 'Press Start 2P', cursive;
  font-size: 1.5rem;
  padding: .5rem;
}

.design-skills {
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 50%;
  width: 50%;
  left: 0;
  text-align: center;
  background-image: url('https://i.imgur.com/XggNr8B.png');
  background-size: 25%;
  background-repeat: no-repeat;
  transition: all 0.3s ease-in-out;
  border-style: solid;
  border-width: .25rem;
  border-image: 
    linear-gradient(
      to right, 
      #06f5d8, 
      #000000
    ) 1 0 100%;
  padding: 1rem;
  list-style: none;
}

.design-skills:hover {
  background-size: 30%;
}

.design-skills li::before {
  content: "\2022";
  color: #7a39b2; /* Change the color */
  font-size: 2rem;
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 2rem; /* Also needed for space (tweak if needed) */
}

.design-skills h2 {
  padding-bottom: 2rem;
  font-family: 'Press Start 2P', cursive;
  font-size: 1.5rem;
  padding: .5rem;
}

.back-end-skills {
  display: flex;
  flex-direction: column;
  position: absolute;
  background-image: url('https://i.imgur.com/tQnkAMq.png');
  background-size: 25%;
  background-repeat: no-repeat;
  transition: all 0.3s ease-in-out;
  height: 50%;
  width: 50%;
  right: 0;
  text-align: center;
  border-style: solid;
  border-width: .25rem;
  border-image: 
    linear-gradient(
      to left, 
      #06f5d8, 
      #000000
    ) 1 0 100%;
  padding: 1rem;
  list-style: none;
}

.back-end-skills:hover {
  background-size: 30%;
}

.back-end-skills li::before {
  content: "\2022";
  color: #7a39b2; /* Change the color */
  font-size: 2rem;
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 2rem; /* Also needed for space (tweak if needed) */
}

.back-end-skills h2 {
  padding-bottom: 2rem;
  font-family: 'Press Start 2P', cursive;
  font-size: 1.5rem;
  padding: .5rem;
}

.other-skills {
  display: flex;
  flex-direction: column;
  position: absolute;
  background-image: url('https://i.imgur.com/qCtZnIb.png');
  background-size: 25%;
  background-repeat: no-repeat;
  transition: all 0.3s ease-in-out;
  height: 50%;
  width: 50%;
  left: 0;
  text-align: center;
  border-style: solid;
  border-width: .25rem;
  border-image: 
    linear-gradient(
      to top, 
      #7a39b2, 
      #000000
    ) 1 100%;
}

.other-skills:hover {
  background-size: 30%;
}

.other-skills li::before {
  content: "\2022";
  color: #06f5d8; /* Change the color */
  font-size: 2rem;
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 2rem; /* Also needed for space (tweak if needed) */
}

.other-skills h2 {
  padding-bottom: 2rem;
  font-family: 'Press Start 2P', cursive;
  font-size: 1.5rem;
  padding: .5rem;
}

.contact {
  text-align: center;
  padding-top: 10rem;
  font-family: "Saira Condensed", cursive;
  font-size: 5rem;
  text-shadow: -.5px .5px 0 #06f5d8, -1px 1px 0 #7a39b2;
}

.wrapper {
  min-width: 100%;
  height: 75px;
  display: flex;
  justify-content: center;
  height: 100%;
  margin-bottom: .75rem;
}

.wrapper .button {
  height: 50px;
  width: 50px;
  float: left;
  margin: 0 5px;
  overflow: hidden;
  background-image: linear-gradient(
    to right,
    #000000,
    #06f5d8
  ) 1 0 100%;;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: -2.5px 2.5px 2.5px #06f5d8;
  transition: all 0.3s ease-out;
}

.wrapper .button:hover {
  width: 175px;
}

.wrapper .button:active {
  transition: 0.1s ease-in-out;
  box-shadow: -5px 5px 5px #7a39b2;
}

.wrapper .button .icon {
  display: inline-block;
  height: 50px;
  width: 50px;
  text-align: center;
  border-radius: 50px;
  box-sizing: border-box;
  line-height: 50px;
  transition: all 0.3s ease-out;
}

.wrapper a:nth-child(1):hover .icon{
  background: #0077b5;
}
.wrapper a:nth-child(2):hover .icon{
  background: #b6b1b0;
}
.wrapper a:nth-child(3):hover .icon{
  background: #1877f2;
}
.wrapper a:nth-child(4):hover .icon{
  background: #1DA1F2;
}

.wrapper a:nth-child(5):hover .icon{
  background: #f3faff;
}

.wrapper a:nth-child(6):hover .icon{
  background: #e62117;
}

.wrapper .button .icon .icons {
  font-size: 25px;
  line-height: 50px;
  transition: all 0.3s ease-out;
}

.wrapper .button:hover .icon .icons {
  color: #000000;
}

.wrapper .button span {
  font-size: 20px;
  font-weight: 500;
  line-height: 50px;
  margin-left: 10px;
  transition: all 0.3s ease-out;
}

.wrapper a:nth-child(1) span{
  color: #0077b5;
}

.wrapper a:nth-child(2) span{
  color: #b6b1b0;
}

.wrapper a:nth-child(3) span{
  color: #1877f2;
}

.wrapper a:nth-child(4) span{
  color: #1DA1F2;
}

.wrapper a:nth-child(5) span{
  color: #f3faff;
}

.wrapper a:nth-child(6) span{
  color: #e62117;
}

@media(max-width: 35.94rem){
  .wrapper .button:hover {
    width: 50px;
  }

  .about-image:active {
    opacity: 1;
    transform: scale(1);
  }

  .about-image {
    margin-bottom: 2.5rem;
  }
}

.slider {
	font-family: sans-serif;
	scroll-snap-type: x mandatory;	
	display: flex;
	-webkit-overflow-scrolling: touch;
	overflow-x: scroll;
}

section {
	border-right: 1px solid #06f5d8;
	padding: 1rem;
	min-width: 100vw;
	height: 100%;
	scroll-snap-align: start;
	text-align: center;
	position: relative;
}

.projects-title	{
	text-align: center;
	width: 100%;
	left: 0;
	font-size: calc(1rem + 3vw);
  text-shadow: -1px 1px 0 #06f5d8, -2px 2px 0 #7a39b2;
}

.projects-links {
  color: #06f5d8;
  text-decoration: underline #7a39b2;
}

.resume-image {
  display: flex;
  justify-content: center;
}